import { formAnatomy } from "@chakra-ui/anatomy";

const form = {
  parts: formAnatomy.keys,
  baseStyle: {},
  variants: {
    floating: floatingVariant,
  },
  defaultProps: {
    variant: floatingVariant,
  },
};

export default form;

// https://github.com/chakra-ui/chakra-ui-docs/issues/212
function floatingVariant() {
  const activeLabelStyles = {
    transform: "translateY(-20px) translateX(5px)",
    fontWeight: "normal",
    fontSize: "sm",
    color: "ui.teal.400",
  };

  return {
    container: {
      _focusWithin: {
        label: {
          ...activeLabelStyles,
        },
        textarea: {
          borderColor: "ui.teal.400",
          caretColor: "ui.teal.400",
          outline: "none",
          boxShadow: "none",
        },
      },
      // non-empty input
      "input:not(:placeholder-shown)": {
        borderColor: "ui.teal.400",
        // chakraui doesn't have caretColor prop so it wouldn't read chakra theme
        caretColor: "#0A99A0",
        // When non-empty and field has lost focus
        "&:not(:focus)": {
          borderColor: "ui.black",
          "+ label": {
            ...activeLabelStyles,
            color: "ui.bodyText",
          },
        },
      },
      "textarea:not(:placeholder-shown)": {
        borderColor: "ui.teal.400",
        "&:not(:focus)": {
          borderColor: "ui.black",
          "+ label": {
            ...activeLabelStyles,
            color: "ui.bodyText",
          },
        },
      },
      label: {
        position: "absolute",
        backgroundColor: "white",
        fontSize: "md",
        lineHeight: "taller",
        top: 0,
        left: 0,
        mx: 2,
        px: 1,
        my: 2.5,
        zIndex: "overlay",
        cursor: "inherit",
      },
      input: {
        fontSize: "md",
        border: "1px solid",
        borderRadius: "4px",
        fontWeight: "normal",
        color: "ui.black",
        px: 4,
        py: 6,
        _placeholder: {
          color: "ui.bodyText",
        },
        _hover: {
          borderColor: "ui.teal.400",
        },
      },
      select: {
        _focus: {
          borderColor: "ui.teal.400",
        },
        _hover: {
          borderColor: "ui.teal.400",
        },
      },
      textarea: {
        px: 4,
        py: 6,
        _focus: {
          borderColor: "ui.teal.400",
        },
        _hover: {
          borderColor: "ui.teal.400",
        },
      },
    },
  };
}
