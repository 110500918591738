import {
  Input,
  IconButton,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React from "react";
import Router from "next/router";
import { buildCollectionLink } from "utils/buildLinks";
import { SearchData } from "oe-shared/models";
import { Defect } from "shared/errors";

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  searchData?: SearchData;
}

/**
 * We currently have to save old search data around in case we navigate
 * to a book page, which as no search link. In this case, we use the old data
 */
let savedSearchData: null | SearchData = null;

/**
 * Search component providing input and
 * search button with styles and defaults.
 * Fetches new description whenever
 * collection.searchDataUrl changes
 */

const Search: React.FC<SearchProps> = ({ searchData }) => {
  const [value, setValue] = React.useState("");
  // update the searchData if we have new data
  if (searchData) {
    savedSearchData = searchData ?? null;
  }

  // show no searchbar if we cannot perform a search or there was an error
  if (!savedSearchData) return null;

  // handle the search
  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!savedSearchData)
      throw new Defect("Cannot perform search. No search template available.");

    if (!value) return;
    const searchTerms = encodeURIComponent(value);
    const url = createSearchUrl(
      savedSearchData.template,
      savedSearchData.url,
      searchTerms,
    );
    if (!url) return;
    const link = buildCollectionLink(url);
    Router.push(link);
  };

  return (
    <form
      onSubmit={onSearch}
      aria-label="Sitewide"
      role="search"
      style={{ paddingLeft: "12px", paddingRight: "12px" }}
    >
      <InputGroup>
        <Input
          variant="filled"
          id="search-bar"
          type="search"
          name="search"
          title={savedSearchData?.shortName}
          placeholder="Search"
          aria-label="Enter search keyword or keywords"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {/* eslint-disable-next-line react/no-children-prop */}
        <InputRightElement children={<SearchButton />} />
      </InputGroup>
    </form>
  );
};

function createSearchUrl(
  templateString: string,
  searchUrl: string,
  query: string,
) {
  return new URL(
    templateString.replace("{searchTerms}", query),
    searchUrl,
  ).toString();
}

const SearchButton: React.FC = () => (
  <IconButton
    colorScheme="teal"
    boxShadow="none"
    type="submit"
    aria-label="submit"
    borderRadius="md"
    icon={<SearchOutlinedIcon />}
  />
);

export default Search;
