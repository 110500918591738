import React from "react";
import ContextProvider from "components/context/ContextProvider";
import { useRouter } from "next/router";
import { PageLoader } from "components/chakra-ui/LoadingIndicator";
import SiteHead from "components/chakra-ui/Head";
import { Box } from "@chakra-ui/react";

/* Page without Header and Footer should wrap pages that should not have sitewide navigation */

const Page: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { isFallback } = useRouter();
  /**
   * If we are in a static generation fallback, we have no
   * library information yet so render a loader.
   */
  if (isFallback) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <PageLoader />
      </Box>
    );
  }

  const { children } = props;

  return (
    <>
      <SiteHead />
      <ContextProvider>{children}</ContextProvider>
    </>
  );
};

export default Page;
