const global = {
  html: {
    scrollBehavior: "smooth",
  },
  body: {
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    color: "ui.bodyText",
    fontSize: "md",
    fontWeight: "normal",
    lineHeight: "normal",
  },
  a: {
    color: "ui.teal.400",
    textDecoration: "none",
  },
};

export default global;
