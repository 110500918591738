import OPDSParser, { OPDSFeed, OPDSEntry } from "opds-feed-parser";
import { feedToCollection, entryToBook } from "oe-shared/models";
import {
  parseSearchData,
  validate,
  handleCMError,
  handleFetchError,
  handleParsingError,
  json,
  text,
} from "oe-shared/fetch";

const parser = new OPDSParser();

/**
 * Fetchers
 */

export const fetchBook = (
  info: RequestInfo,
  catalogUrl: string,
  init?: RequestInit,
) =>
  fetch(info, init)
    .catch(handleFetchError(info))
    .then(handleCMError)
    .then(text)
    .then(parser.parse)
    .catch(handleParsingError(info))
    .then(validate(OPDSEntry, info))
    .then(entry2Book(catalogUrl));

export const fetchCollection = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init)
    .catch(handleFetchError(input))
    .then(handleCMError)
    .then(text)
    .then(parser.parse)
    .catch(handleParsingError(input))
    .then(validate(OPDSFeed, input))
    .then(feed2Collection(input));

export const fetchLoans = (input: RequestInfo, init?: RequestInit) =>
  fetchCollection(input, init).then((col) => col.books);

export const fetchSearchData = (url: string) =>
  fetch(url)
    .catch(handleFetchError(url))
    .then(handleCMError)
    .then(text)
    .then((text) => parseSearchData(text, url));

export const fetchFullfillment = (info: RequestInfo, opt?: RequestInit) =>
  fetch(info, opt).catch(handleFetchError(info)).then(handleCMError).then(json);

export const entry2Book = (catalogUrl: string) => async (entry: OPDSEntry) =>
  entryToBook(entry, catalogUrl);

export const feed2Collection =
  (info: RequestInfo) => async (feed: OPDSFeed) => {
    const url = typeof info === "string" ? info : info.url;
    return feedToCollection(feed, url);
  };
