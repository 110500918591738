import {
  Link as ChakraLink,
  Flex,
  Box,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Flex
      as="footer"
      bg="ui.gray.xxlight"
      pl={{ base: 20, lg: 41 }}
      pt={{ base: 16, md: 12 }}
      pb={{ base: 20, md: 28, lg: 14 }}
      flexDir={{ base: "column", md: "row" }}
    >
      <Box mr={{ md: 40, lg: 80 }}>
        <Text as="h2" textStyle="headers.tertiary" mb={8}>
          Open eBooks
        </Text>
        <UnorderedList spacing={{ base: 4, md: 6 }} m="0">
          <FooterLink href="/">Home</FooterLink>
          <FooterLink href="/about">About</FooterLink>
          <FooterLink href="/faq">FAQs</FooterLink>
          <FooterLink href="/educators">Educators</FooterLink>
          <FooterLink href="/privacy">Privacy</FooterLink>
          <FooterLink href="/partners">Partners</FooterLink>
        </UnorderedList>
      </Box>
      <Box pt={{ base: 12, md: 0 }}>
        <Text as="h2" textStyle="headers.tertiary" mb={8}>
          Support
        </Text>
        <UnorderedList spacing={{ base: 4, md: 6 }} m="0">
          <FooterLink href="/contact">Contact</FooterLink>
        </UnorderedList>
      </Box>
    </Flex>
  );
};

type FooterLinkProps = {
  href: string;
} & React.ComponentProps<typeof ChakraLink>;

const FooterLink: React.FC<React.PropsWithChildren<FooterLinkProps>> = ({
  href,
  children,
  sx: _sx,
  ref: _ref,
  as: _as,
  ...props
}) => {
  return (
    <ListItem listStyleType="none">
      <Link href={href} passHref legacyBehavior>
        <ChakraLink textDecoration="none" color="ui.bodyText" {...props}>
          {children}
        </ChakraLink>
      </Link>
    </ListItem>
  );
};

export default Footer;
