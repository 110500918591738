/**
 * Design constants
 */
export const HEADER_HEIGHT = "64px";

const sizes = {
  header: HEADER_HEIGHT,
};

export default sizes;
