import OPDSParser, { OPDSEntry, OPDSFeed } from "opds-feed-parser";
import {
  validate,
  feed2Collection,
  parseSearchData,
  entry2Book,
} from "./parsers";
import { handleFetchError, handleCMError, handleParsingError } from "./errors";
import { text, json } from "./helpers";
import { CirculationManager } from "../CirculationManager";
import { CredentialsService } from "../auth";
import {
  EncodedNewBookmarkAnnotation,
  EncodedNewPositionAnnotation,
  ParseAnnotationCollectionResult,
  parseAnnotationCollection,
  parseBookmarkAnnotation,
} from "../models/annotations";
import { CMServerError, PatronMustOptInToSyncError } from "../errors";

const parser = new OPDSParser();

export class CirculationManagerFetcher {
  constructor(
    private readonly cm: CirculationManager,
    private readonly credService: CredentialsService,
  ) {}

  fetchAuthenticated = async (input: RequestInfo, init?: RequestInit) => {
    const credentials = await this.credService.get(this.cm);
    return fetch(input, {
      ...init,
      headers: new Headers({
        ...init?.headers,
        Authorization: credentials.token,
      }),
    });
  };

  fetchBook = (input: RequestInfo, init?: RequestInit) => {
    return this.fetchAuthenticated(input, init)
      .catch(handleFetchError(input))
      .then(handleCMError)
      .then(text)
      .then(parser.parse)
      .catch(handleParsingError(input))
      .then(validate(OPDSEntry, input))
      .then(entry2Book(this.cm.catalogUrl));
  };

  fetchCollection = (input: RequestInfo, init?: RequestInit) =>
    this.fetchAuthenticated(input, init)
      .catch(handleFetchError(input))
      .then(handleCMError)
      .then(text)
      .then(parser.parse)
      .catch(handleParsingError(input))
      .then(validate(OPDSFeed, input))
      .then(feed2Collection(input));

  fetchSearchData = (url: string) =>
    this.fetchAuthenticated(url)
      .catch(handleFetchError(url))
      .then(handleCMError)
      .then(text)
      .then((text) => parseSearchData(text, url));

  fetchFulfillment = (
    input: RequestInfo,
    init?: RequestInit,
  ): Promise<{ book_vault_uuid: string; isbn: string }> =>
    this.fetchAuthenticated(input, init)
      .catch(handleFetchError(input))
      .then(handleCMError)
      .then(json);

  fetchAnnotations = (
    input: RequestInfo,
    init?: RequestInit,
  ): Promise<ParseAnnotationCollectionResult> =>
    this.fetchAuthenticated(input, init)
      .catch(handleFetchError(input))
      .then(handleCMError)
      .then(json)
      .then(parseAnnotationCollection);

  postReadingPosition = (
    input: RequestInfo,
    position: EncodedNewPositionAnnotation,
  ) =>
    this.fetchAuthenticated(input, {
      method: "POST",
      body: JSON.stringify(position),
    })
      .catch(handleFetchError(input))
      // custom error handling to catch patron-must-opt-in errors
      .then(handleOptInError);

  postBookmark = (input: RequestInfo, bookmark: EncodedNewBookmarkAnnotation) =>
    this.fetchAuthenticated(input, {
      method: "POST",
      body: JSON.stringify(bookmark),
    })
      .catch(handleFetchError(input))
      .then(handleOptInError)
      .then(json)
      .then(parseBookmarkAnnotation);

  deleteAnnotation = (input: RequestInfo, init?: RequestInit) =>
    this.fetchAuthenticated(input, init)
      .catch(handleFetchError(input))
      .then(handleCMError);

  fetchLoans = async (url: string) => {
    const collection = await this.fetchCollection(url);
    return collection.books;
  };

  updateSyncingToTrue = (input: RequestInfo, init?: RequestInit) =>
    this.fetchAuthenticated(input, {
      ...{
        method: "PUT",
        headers: {
          "Content-Type": "vnd.librarysimplified/user-profile+json",
        },
        body: JSON.stringify({
          settings: {
            "simplified:synchronize_annotations": true,
          },
        }),
      },
      ...init,
    })
      .catch(handleFetchError(input))
      .then(handleCMError);
}

async function handleOptInError(res: Response): Promise<Response> {
  if (!res.ok) {
    const e = await CMServerError.fromResponsePromise(res);
    if (
      e._tag === "Defect" &&
      e.status === 403 &&
      e.title === "Patron must opt in."
    ) {
      throw new PatronMustOptInToSyncError();
    }
    throw e;
  }
  return res;
}
