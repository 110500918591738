import {
  Container,
  Link,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { MDXComponents } from "mdx/types";
import React from "react";
import { createTOCAnchors } from "./TOC";
import LayoutPage from "../LayoutPage";

const maxContentWidth = "34rem";

const MDXText: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof Text>>
> = ({ children, ...props }) => (
  <Text maxWidth={maxContentWidth} {...props}>
    {children}
  </Text>
);

const Wrapper = ({ children }: React.PropsWithChildren<unknown>) => (
  <LayoutPage>
    <Container as="main" maxWidth="container.md" marginBottom="8em">
      {children}
    </Container>
  </LayoutPage>
);

const H1 = ({ children }: React.PropsWithChildren<unknown>) => (
  <MDXText textStyle="headers.primary" as="h1" marginTop="16" marginBottom="2">
    {children}
  </MDXText>
);

const H2 = ({ children }: React.PropsWithChildren<unknown>) => {
  const { id } = createTOCAnchors(children);
  return (
    <MDXText
      textStyle="headers.secondary"
      as="h2"
      marginTop="8"
      marginBottom="2"
      id={id}
      // accommodates the height of the sticky nav when jumping using FAQ table of contents
      _before={{
        display: "block",
        content: '" "',
        marginTop: "-3em",
        height: "3em",
        visibility: "hidden",
        pointerEvents: "none",
      }}
    >
      {children}
    </MDXText>
  );
};

const H3 = ({ children }: React.PropsWithChildren<unknown>) => (
  <MDXText textStyle="headers.tertiary" as="h3" marginTop="8" marginBottom="2">
    {children}
  </MDXText>
);

const H4 = ({ children }: React.PropsWithChildren<unknown>) => (
  <MDXText textStyle="headers.tertiary" as="h4" marginTop="8" marginBottom="2">
    {children}
  </MDXText>
);

const P = ({ children }: React.PropsWithChildren<unknown>) => (
  <MDXText marginTop="md" marginBottom="md">
    {children}
  </MDXText>
);

const OL = ({ children }: React.PropsWithChildren<unknown>) => (
  <OrderedList marginTop="md" marginBottom="md" maxWidth={maxContentWidth}>
    {children}
  </OrderedList>
);

const UL = ({ children }: React.PropsWithChildren<unknown>) => (
  <UnorderedList marginTop="md" marginBottom="md" maxWidth={maxContentWidth}>
    {children}
  </UnorderedList>
);

export const mdxComponents: MDXComponents = {
  wrapper: Wrapper,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: P,
  ol: OL,
  ul: UL,
  a: Link,
};
