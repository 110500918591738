const typography = {
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.375rem", // 22px
    "3xl": "1.5rem", // 24px
    "4xl": "1.625rem", // 26px
    "5xl": "1.75rem", // 28px
    "6xl": "1.875rem", // 30px
    "7xl": "2rem", // 32px
    "8xl": "2.125rem", // 34px
    "9xl": "2.25rem", // 36px
    xxl: "2.375rem", // 38px
    "2xxl": "2.5rem", // 40px
    "3xxl": "2.625rem", // 42px
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "13": "3.25rem",
    "14": "3.5rem",
    "15": "3.75rem",
    "16": "4rem",
  },
};

export default typography;
