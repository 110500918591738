import React, { useState } from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import bookCoverFallbackImage from "./svg/book-cover-fallback.svg";
import Image from "next/image";
import { imageSizes } from "ui/chakra";

export default function BookCover({
  src,
  title,
  shadow = "carouselBook",
}: {
  src: string;
  title: string;
  shadow?: string;
}) {
  const [imageError, setImageError] = useState(false);

  return (
    <Box
      shadow={shadow}
      position="relative"
      width="100%"
      height={0}
      paddingInlineEnd={0}
      paddingTop="143%"
    >
      <Skeleton
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex={0}
      />
      <Image
        src={imageError ? bookCoverFallbackImage : src}
        alt={`Cover of book: ${title}`}
        onError={() => setImageError(true)}
        style={{ zIndex: 2 }}
        fill
        sizes={imageSizes}
      />
    </Box>
  );
}
