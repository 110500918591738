import { baseTheme as chakraBaseTheme } from "@chakra-ui/react";

/**
 * And we override certain sizes with our own definitions.
 * Can be used in width, height, maxWidth, etc.
 */
const sizes = {
  ...chakraBaseTheme.sizes,
  // useful as the default max content width in many places.
  lg: "34rem",
};

export default sizes;
