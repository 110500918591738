const InputStyle = {
  baseStyle: {},
  sizes: {},
  variants: {
    filled: filledVariant,
  },
  defaultProps: {},
};

function filledVariant() {
  return {
    field: {
      backgroundColor: "ui.gray.light",
      _placeholder: {
        color: "ui.semiBlack",
      },
      _hover: {
        backgroundColor: "ui.gray.light",
      },
      _focus: {
        backgroundColor: "ui.gray.light",
        borderColor: "ui.teal.400",
      },
      _focusVisible: {
        backgroundColor: "ui.gray.light",
        borderColor: "ui.teal.400",
        _placeholder: {
          color: "ui.gray.dark",
        },
      },
    },
  };
}

export default InputStyle;
