import { extendTheme } from "@chakra-ui/react";
import textStyles from "./foundations/text";
import breakpoints from "./foundations/breakpoints";
import colors from "./foundations/color";
import global from "./foundations/global";
import typography from "./foundations/typography";
import shadows from "./foundations/shadow";
import space from "./foundations/spacing";
import Button from "./components/button";
import Form from "./components/form";
import Link from "./components/link";
import Select from "./components/select";
import Input from "./components/input";
import radii from "./foundations/raddi";
import zIndices from "./foundations/z-index";
import sizes from "./foundations/sizes";

const theme = extendTheme({
  styles: { global },
  colors,
  ...typography,
  textStyles,
  breakpoints,
  shadows,
  space,
  sizes,
  radii,
  zIndices,
  components: {
    Button,
    Form,
    Link,
    Select,
    Input,
  },
});

export default theme;
