import { Box, Text, chakra } from "@chakra-ui/react";
import BookCover from "./BookCover";
import Link from "./Link";
import { SimpleBook } from "oe-shared/models";
import React from "react";
import { truncateString } from "utils/string";

// eslint-disable-next-line react/display-name
const BookCard = React.forwardRef<
  HTMLLIElement,
  { book: SimpleBook; className?: string }
>(({ book, className }, ref) => {
  return (
    <Box
      as="li"
      aria-label={book.title}
      ref={ref as any}
      className={className}
      transition={"all 0.2s"}
      transform="scale(.97)"
      _hover={{ transform: "scale(1)" }}
    >
      <Link
        bookUrl={book.url}
        aria-label={`View ${book.title}`}
        sx={{ "&:hover": { textDecoration: "none" } }}
      >
        <BookCover src={book.fullImageUrl} title={book.title} />
        <Text
          my={2}
          fontWeight="bold"
          lineHeight="shorter"
          wordBreak="break-word"
          fontSize={{ base: "xs", md: "md" }}
        >
          {book.title}
        </Text>
        <Text fontSize={{ base: "xs", md: "md" }} lineHeight="shorter">
          By {truncateString(book.authors.join("; "), 42, false)}
        </Text>
      </Link>
    </Box>
  );
});

/**
 * Use the chakra factory to make it accept style props which get transformed
 * into a className that the BookCard receives and places on its children.
 */
export default chakra(BookCard);
