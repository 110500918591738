import Link from "next/link";
import { useRouter } from "next/router";
import { Link as ChakraLink, chakra, HStack, ListItem } from "@chakra-ui/react";
import { isSelected } from "./DesktopHeaderLink";

const bg = (color: string, isSelected: boolean) =>
  isSelected ? "ui.gray.light" : color;

export const MobileHeaderLink: React.FC<
  React.PropsWithChildren<
    React.ComponentPropsWithoutRef<typeof ChakraLink> & {
      icon: React.ReactNode;
    }
  >
> = ({ children, icon, href, ...props }) => {
  const router = useRouter();
  const selected = isSelected(router.pathname, href);

  return (
    <ListItem>
      <Link href={href} passHref>
        <ChakraLink {...props}>
          <HStack
            spacing={3}
            as="span"
            py={4}
            px={8}
            sx={{
              color: "ui.bodyText",
              fontSize: "xl",
              transitionProperty: "background",
              transitionDuration: "ultra-fast",
              transitionTimingFunction: "ease-in",
              bg: bg("ui.white", selected),
              _focus: {
                bg: bg("ui.gray.xlight", selected),
              },
              _hover: {
                bg: bg("ui.gray.xlight", selected),
              },
            }}
          >
            <chakra.span>{icon}</chakra.span>
            <chakra.span>{children}</chakra.span>
          </HStack>
        </ChakraLink>
      </Link>
    </ListItem>
  );
};
