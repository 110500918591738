/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Divider,
  Box,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import Link from "next/link";
import useGetCredentials from "hooks/useGetCredentials";
import { AUTH_COOKIE_NAME, SIGNIN_PICKER_PATH } from "utils/constants";
import { NextRouter, useRouter } from "next/router";

export type AuthButtonProps = React.ComponentProps<typeof Button>;

/**
 * You can now either use this without passing `as`, or you can pass
 * as={MenuAuthButtonContent} to make it look like a menu button.
 *
 * The component will adapt to be a button or a link depending if you are
 * signed in or signed out.
 */
export const AuthButton = forwardRef<HTMLButtonElement, AuthButtonProps>(
  (props, ref) => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const credentials = useGetCredentials();
    const router = useRouter();
    const isAuthenticated = !!credentials;

    return isAuthenticated ? (
      <>
        <Button
          ref={ref}
          onClick={onOpen}
          leftIcon={<LogoutOutlinedIcon />}
          {...props}
        >
          Sign out
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            width={72}
            borderRadius={10}
            shadow="modal"
            aria-label="Confirmation"
          >
            <ModalHeader id="Confirmation">Sign out</ModalHeader>
            <ModalCloseButton />
            <ModalBody paddingBottom={6}>
              <Text>Are you sure you want to sign out?</Text>
            </ModalBody>
            <Divider borderColor="ui.gray.light" />
            <ModalFooter justifyContent="space-around" padding={0} margin={0}>
              <Button
                backgroundColor="none"
                _hover={{
                  backgroundColor: "ui.gray.light",
                }}
                width="100%"
                height={16}
                color="ui.teal.400"
                borderRadius="0px 0px 0px 10px"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Box
                borderLeft="1px solid"
                height={16}
                borderColor="ui.gray.light"
              />
              <Button
                height={16}
                width="100%"
                backgroundColor="none"
                _hover={{
                  backgroundColor: "ui.gray.light",
                }}
                borderRadius="0px 0px 10px 0px"
                color="ui.danger"
                fontWeight="bold"
                onClick={() => signOut(router)}
              >
                Sign out
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ) : (
      <Link href={SIGNIN_PICKER_PATH} passHref legacyBehavior>
        <Button ref={ref} as="a" leftIcon={<LoginOutlinedIcon />} {...props}>
          Sign in
        </Button>
      </Link>
    );
  },
);

/**
 * Signs out and clears the localstorage,
 *
 * The signout method here uses the router.push to fix
 * a weird bug in Clever signout where `window.location.href` wouldn't
 * clear the cookie resulting in a login loop.
 */
export async function signOut(router: NextRouter) {
  localStorage.clear();
  Cookies.remove(AUTH_COOKIE_NAME);

  // if already on the homepage, reload the page
  // otherwise, push users to the homepage
  if (window.location.pathname === "/") {
    router.reload();
  } else {
    router.push(new URL("/", window.location.origin), undefined, {
      shallow: false,
    });
  }
}
