import { Defect } from "shared/errors";
import React from "react";
import useSWR, {
  Fetcher,
  Middleware,
  SWRConfiguration,
  SWRResponse,
  Key as SWRKey,
} from "swr";

type SWRResponseWithCancel<Data, Error> = SWRResponse<Data, Error> & {
  cancelInflight: () => void;
};

/**
 * Wraps `useSWR` to add a `cancel` method which cancels inflight responses.
 */
const useSWRAbortable = <Data, Error, Key extends SWRKey>(
  key: Key,
  fetcher: Fetcher<Data, Key>,
  config: SWRConfiguration<Data, Error, Fetcher<Data, Key>> | undefined,
): SWRResponseWithCancel<Data, Error> => {
  // we have to type cast the following. See:
  // https://github.com/vercel/swr/pull/1160
  const swr = useSWR(key, fetcher, {
    ...config,
    use: [cancellable, ...(config?.use ?? [])],
  }) as SWRResponseWithCancel<Data, Error>;

  return swr;
};

export default useSWRAbortable;

/**
 * Adds a cancel callback to the SWR library to cancel an inflight request.
 */
const cancellable: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    // save a ref to our current abort controller
    const controllerRef = React.useRef<AbortController | null>(null);

    // update the fetcher to use the abort controller
    const fetcherWithCancel: NonNullable<typeof fetcher> = (...args) => {
      // if there is an existing controller, this means there is a pending
      // request we need to abort before making a new one.
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      controllerRef.current = new AbortController();

      // this should never happen
      if (!fetcher) {
        throw new Defect("fetcher is not defined");
      }

      // extend the fetcher with the abort controller
      return fetcher(...args, { signal: controllerRef.current.signal });
    };

    // Actual SWR hook with the extended fetcher.
    const swr = useSWRNext(key, fetcher ? fetcherWithCancel : null, config);

    // create a function to cancel any inflight requests
    function cancelInflight() {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    }

    /**
     * Add the abort controller to the return value
     */
    return {
      ...swr,
      cancelInflight,
    };
  };
};
