import React from "react";
import { chakra } from "@chakra-ui/react";
import { ErrorBoundary } from "components/chakra-ui/ErrorBoundary";
import Footer from "components/chakra-ui/Footer";
import { Header } from "components/chakra-ui/header";
import { SearchData } from "oe-shared/models";
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav";

export const CONTENT_ID = "cpw-content";

const Layout: React.FC<
  React.PropsWithChildren<{ searchData?: SearchData }>
> = ({ children, searchData }) => {
  return (
    <ErrorBoundary>
      <SkipNavLink zIndex="skipLink">Skip to content</SkipNavLink>
      <Header searchData={searchData} />

      <SkipNavContent />
      <chakra.main>{children}</chakra.main>

      <Footer />
    </ErrorBoundary>
  );
};

export default Layout;
