import { IS_SERVER, NEW_RELIC_LICENSE_KEY, RELEASE_STAGE } from "utils/env";
import { NewRelicBrowserSetup } from "./newrelic";
import Script from "next/script";

export const NewRelicSnippet = () => {
  if (IS_SERVER) return null;

  if (!NEW_RELIC_LICENSE_KEY) {
    console.warn("Missing License Key.");
    return null;
  }

  return (
    <Script
      type="text/javascript"
      src="/newrelic-browser.js"
      onLoad={() => {
        /**
         * The newrelic-browser.js contains the basic functions from New relic,
         * after the script has loaded on the page, we need to provide the application_id so
         * we know where to send the metrics to.
         */
        NewRelicBrowserSetup(RELEASE_STAGE);
      }}
    />
  );
};

export default NewRelicSnippet;
