import { OPDS1 } from "@nypl/opds";

/**
 * Types for our OPDS1-based book model
 */

export interface FulfillmentLink {
  contentType: OPDS1.ReadOnlineMediaType;
  url: string;
}

export type BorrowableBook = Book<{
  status: "borrowable";
  borrowUrl: string;
}>;

export type FulfillableBook = Book<{
  status: "fulfillable";
  fulfillmentLinks: readonly FulfillmentLink[];
  revokeUrl: string | null;
}>;

export type OpenEBook = BorrowableBook | FulfillableBook;
export type EmptyObject = Record<never, unknown>;

export type BookMedium = "http://schema.org/EBook" | "http://schema.org/Book";

/**
 * A smaller version of a book for when we don't need all the details
 */
export type SimpleBook = {
  id: string;
  title: string;
  authors: string[];
  // full sized image
  fullImageUrl: string;
  url: string;
};

export type Book<Status = EmptyObject> = Readonly<
  Status &
    SimpleBook & {
      // thumbnail sized image
      imageUrl: string;
      series?: {
        name: string;
        position?: number;
      } | null;
      contributors?: string[];
      subtitle?: string;
      summary?: string;
      availability?: {
        status: "available";
        since?: string;
        until?: string;
      };
      holds?: {
        total: number;
        position?: number;
      } | null;
      copies?: {
        total: number;
        available: number;
      } | null;
      publisher?: string;
      published?: string;
      category?: string;
      genres?: string[];
      language?: string;
      relatedUrl: string | null;
      trackOpenBookUrl: string | null;
    }
>;

export const SIMPLIFIED_GENRE =
  "http://librarysimplified.org/terms/genres/Simplified/";
export const SIMPLIFIED_FICTION_STATUS =
  "http://librarysimplified.org/terms/fiction/";
