import { CM } from "utils/env";
const { catalogUrl } = CM;

export const trailingSlashRegex = /\/$/;

export function buildCollectionLink(collectionUrl: string) {
  // if there is no collection url, or it is the catalog root, go home
  if (
    !collectionUrl ||
    collectionUrl.replace(trailingSlashRegex, "") === catalogUrl
  ) {
    return "/app/".replace(trailingSlashRegex, "");
  }
  return `/app/collection/${encodeURIComponent(collectionUrl)}`.replace(
    trailingSlashRegex,
    "",
  );
}

export function buildBookLink(bookUrl: string) {
  return `/app/book/${encodeURIComponent(bookUrl)}`.replace(
    trailingSlashRegex,
    "",
  );
}
