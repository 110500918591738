/**
 * This is a class that is meant to be used to construct circulation manager
 * API urls, and allow switching CM instances. It is a class because we need
 * to pass it some data to instantiate it, and a class gives us a free constructor.
 * We should not inherit from this class or anything else.
 */
export class CirculationManager {
  constructor(
    public currentServer: string,
    private librarySlug: string,
    public catalogName: string,
  ) {}

  get isProductionServer() {
    return /https:\/\/(www\.)?circulation\.openebooks\.us/.test(
      this.currentServer,
    )
      ? true
      : false;
  }

  get annotationsUrl() {
    return new URL("/annotations/", this.currentServer).toString();
  }

  get catalogUrl() {
    return new URL(`/${this.librarySlug}`, this.currentServer).toString();
  }

  get cleverAuthUrl() {
    return new URL(
      `/${this.librarySlug}/oauth_authenticate?provider=Clever`,
      this.currentServer,
    ).toString();
  }

  get firstBookAuthUrl() {
    return new URL("/http_basic_auth_token", this.currentServer).toString();
  }

  get loansUrl() {
    return new URL(`/${this.librarySlug}/loans`, this.currentServer).toString();
  }

  get patronUrl() {
    return new URL("/patrons/me", this.currentServer).toString();
  }

  setServer(newServer: string) {
    this.currentServer = newServer;
  }

  /**
   * Fetch functions
   */
}
