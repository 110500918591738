/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LayoutPage from "components/chakra-ui/LayoutPage";
import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import { CATALOG_ROOT_PATH } from "utils/constants";
import Link from "next/link";
import { useRouter } from "next/router";
import NotFoundPage from "pages/404";

export type ErrorComponentProps = {
  title: string;
  detail: string;
  status?: number;
};

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  title,
  detail,
  status,
}) => {
  const router = useRouter();
  useEffect(() => console.error(detail), [detail]);

  return (
    <>
      {status === 404 ? (
        <NotFoundPage />
      ) : (
        <LayoutPage>
          <Flex alignItems="start" gap={3} m={[8, 8, 12]}>
            {status && (
              <Text
                as="h1"
                color="ui.teal.400"
                fontSize={["3rem", "3rem", "6rem"]}
              >
                {status}
              </Text>
            )}
            <Stack mt={[3, 3, 6]}>
              <Text as="p" fontSize={["md", "md", "xl"]} fontWeight="normal">
                We&apos;re sorry...
                <br />
                {title}
              </Text>
              <Flex
                alignItems={["start", "start", "center"]}
                direction={["column", "column", "row"]}
                gap={2}
              >
                <Text
                  as="p"
                  fontSize={["xs", "sm", "l", "xl"]}
                  fontWeight="normal"
                >
                  {shouldRefresh(status) && (
                    <Link href={router.asPath} legacyBehavior>
                      <a
                        href="#"
                        onClick={() => router.reload()}
                        style={{
                          color: "black",
                          textDecoration: "underline",
                        }}
                      >
                        Refresh Page
                      </a>
                    </Link>
                  )}
                  {shouldRefresh(status) ? " or go to" : "Go to"}
                </Text>
                <Link href={CATALOG_ROOT_PATH} legacyBehavior passHref>
                  <Button
                    as="a"
                    colorScheme="teal"
                    leftIcon={<HomeOutlinedIcon />}
                    size={["sm", "sm", "md"]}
                  >
                    Home
                  </Button>
                </Link>
              </Flex>
            </Stack>
          </Flex>
        </LayoutPage>
      )}
    </>
  );
};

function shouldRefresh(statusCode: number | undefined) {
  return statusCode !== 404 && statusCode !== 451;
}

export default ErrorComponent;
