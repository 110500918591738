import { OpenEBooksAuthCredentials } from "oe-shared/auth/types";
import React, { useState } from "react";
import { getCredentialsBrowser } from "utils/auth";

/**
 * A React hook that wraps the getCredentialsBrowser() function to be used
 * when the token needs to be used at the top-level React component.
 *
 * Gets credentials from browser cookies & verifies that the token has not expired
 * Returns the latest credentials from getCredentialsBrowser
 */
export default function useGetCredentials() {
  const [credentials, setCredentials] = useState<
    OpenEBooksAuthCredentials | undefined
  >();
  React.useEffect(() => {
    (async () => {
      const updatedCredentials = await getCredentialsBrowser();
      setCredentials(updatedCredentials);
    })();
  }, []);

  return credentials;
}
