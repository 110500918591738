import { SystemStyleObject } from "@chakra-ui/react";
// TODO: update this
// import { menuLinkStyle } from "header/utils";
import { Dict } from "../types";

export type ColorScheme =
  | "magenta"
  | "teal"
  | "white"
  | "gray"
  | "lightGray"
  | "default";

const sizes = {
  sm: {
    px: 4,
  },
  md: {
    h: "10",
    minW: "10",
    fontSize: "lg",
    fontWeight: "normal",
    lineHeight: "shorter",
    px: 5,
  },
  lg: {
    fontSize: ["sm", "md", "3xl", "5xl"],
    width: ["200px", "250px", "400px"],
    height: ["52px", null, "80px"],
    px: 4,
    py: 0,
  },
  xl: {
    fontSize: { base: "xl", md: "7xl" },
    fontWeight: "black",
    px: { base: 16, md: 24 },
    py: { base: 2.5, md: 5 },
  },
};

const ButtonStyle = {
  baseStyle: {
    _focus: { boxShadow: "outline" },
    _active: { boxShadow: "outline" },
    borderRadius: "primary",
    fontSize: "md",
    fontWeight: "normal",
    lineHeight: 5,
    _hover: {
      textDecoration: "none",
    },
  },
  sizes,
  variants: {
    primary: primaryVariant,
    outline: outlineVariant,
    menuItem: menuItemVariant,
  },
  defaultProps: {
    variant: "primary",
    colorScheme: "default",
    size: "md",
  },
};

function primaryVariant(props: Dict) {
  const color = primaryColors(props.colorScheme);

  return {
    ...color,
  };
}

export function primaryColors(colorScheme: ColorScheme = "default") {
  switch (colorScheme) {
    case "magenta":
      return {
        height: "auto",
        color: "ui.white",
        bgColor: "ui.magenta.400",
        borderRadius: "full",
        boxShadow: "button",
        _hover: {
          bgColor: "ui.magenta.500",
          boxShadow: "none",
        },
      };
    case "teal":
      return {
        color: "ui.white",
        bgColor: "ui.teal.400",
        borderRadius: "full",
        boxShadow: "button",
        _hover: {
          bgColor: "ui.teal.500",
          color: "ui.white",
          boxShadow: "none",
          _disabled: {
            bgColor: "ui.gray.dark",
          },
        },
        _disabled: {
          boxShadow: "none",
          bgColor: "ui.gray.dark",
        },
      };
    case "white":
      return {
        color: "ui.bodyText",
        bgColor: "ui.white",
        boxShadow: "button",
        _hover: {
          bgColor: "ui.gray.light",
          textDecoration: "none",
          _disabled: {
            bgColor: "ui.gray.dark",
          },
        },
      };
    case "gray":
      return {
        bgColor: "ui.gray.dark",
        color: "ui.white",
        boxShadow: "button",
        _disabled: {
          bgColor: "ui.gray.dark",
          color: "ui.white",
        },
        _hover: {
          bgColor: "ui.bodyText",
          boxShadow: "none",
          _disabled: { bg: "ui.gray.light" },
        },
      };
    case "lightGray":
      return {
        bgColor: "ui.gray.xlight",
        color: "ui.gray.xdark",
        fontWeight: "bold",
        _hover: {
          bgColor: "ui.gray.light",
        },
      };
    default:
      return {
        boxShadow: "none",
        color: "ui.bodyText",
        bgColor: "ui.gray.xxlight",
        _hover: {
          bgColor: "ui.teal.500",
          color: "ui.white",
          textDecoration: "none",
        },
      };
  }
}

function outlineVariant() {
  return {
    fontWeight: "normal",
    _active: {
      bgColor: "inherit",
    },
  };
}

function menuItemVariant(): SystemStyleObject {
  return {
    borderRadius: "none",
    height: "initial",
    py: 4,
    px: 8,
    width: "100%",
    justifyContent: "flex-start",
    // ...menuLinkStyle(false)
  };
}

export default ButtonStyle;
