/**
 * the default assumption is that a book is available. See:
 * https://github.com/NYPL-Simplified/Simplified/wiki/OPDS-For-Library-Patrons#opdsavailability---describing-resource-availability
 */
export const DEFAULT_AVAILABILITY = "available";

/**
 * Query Parameters
 */
export const SIGNIN_REDIRECT_QUERY_PARAM = "redirectTo";
export const SIGNIN_ERROR_QUERY_PARAM = "signinError";
export const CLEVER_SIGNIN_QUERY_PARAM_TOKEN = "access_token";

export const AxisNowEncryptionScheme =
  "http://librarysimplified.org/terms/drm/scheme/AxisNow";

export const AUTH_COOKIE_NAME = "OEW_AUTH_COOKIE";

/**
 * App paths
 * Fully qualified paths that include the "library" aka "/app" prefix.
 */
export const SIGNIN_PICKER_PATH = "/app/signin";
export const SIGNIN_CLEVER_PATH = "/app/signin/method/clever";
export const SIGNIN_CLEVER_CALLBACK_PATH = "/app/signin/method/clever/callback";
export const SIGNIN_FIRSTBOOK_PATH = "/app/signin/method/firstbook";
export const CATALOG_ROOT_PATH = "/app";
export const LOANS_PATH = "/app/loans";
export const FAQ_PATH = "/faq";
