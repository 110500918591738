import { SystemStyleObjectRecord } from "@chakra-ui/react";

const textStyles: SystemStyleObjectRecord = {
  // Keep in mind that textStyles prop does not work on <Heading>
  h1: {
    fontSize: ["xl", "4xl", "9xl"],
    fontWeight: "black",
    lineHeight: "shorter",
  },
  // h2 and h3 has the same size, but one is bold, one is black
  h2: {
    fontSize: ["xl", "4xl", "7xl"],
    fontWeight: "bold",
    lineHeight: "shorter",
  },
  h3: {
    fontSize: ["xl", "4xl", "7xl"],
    fontWeight: "black",
    lineHeight: "shorter",
  },
  h4: {
    fontSize: ["lg", "2xl", "4xl"],
    fontWeight: "black",
    lineHeight: "shorter",
  },
  h5: {
    fontSize: ["3xl", "3xl"],
    fontWeight: "bold",
    lineHeight: "shorter",
  },
  h6: {
    fontSize: ["lg", "xl", "xl"],
    fontWeight: "black",
    lineHeight: "shorter",
  },
  //   body text for other sections
  subText: {
    fontWeight: "normal",
    fontSize: ["xl", "xl", "md"],
    lineHeight: ["none", "base", "tall"],
    color: "ui.white",
  },
  // general body text, also dubplicated in "globals".
  body: {
    fontSize: { base: "sm", md: "md" },
    fontWeight: "normal",
    lineHeight: "normal",
  },
};

export default textStyles;
