import { extendTheme } from "@chakra-ui/react";

export const textStyles: ReturnType<typeof extendTheme>["textStyles"] = {
  headers: {
    primary: {
      fontFamily: "body",
      fontSize: ["2rem", "2rem", "3rem"],
      fontWeight: "light",
      lineHeight: "shorter",
    },
    secondary: {
      fontFamily: "body",
      fontSize: ["3xl", "3xl", "5xl"],
      fontWeight: "medium",
      lineHeight: "shorter",
    },
    tertiary: {
      fontFamily: "body",
      fontSize: ["xl", "xl", "3xl"],
      fontWeight: "normal",
      lineHeight: "shorter",
    },
  },
  callouts: {
    regular: {
      fontFamily: "body",
      fontSize: "lg",
      fontWeight: "normal",
      lineHeight: "shorter",
    },
    bold: {
      fontFamily: "body",
      fontSize: "lg",
      fontWeight: "bold",
      lineHeight: "shorter",
    },
    italic: {
      fontFamily: "body",
      fontSize: "lg",
      fontWeight: "normal",
      lineHeight: "shorter",
      fontStyle: "italic",
    },
  },
  body: {
    regular: {
      fontFamily: "body",
      fontSize: "md",
      fontWeight: "light",
      lineHeight: 6,
    },
    bold: {
      fontFamily: "body",
      fontSize: "md",
      fontWeight: "bold",
      lineHeight: 6,
    },
    italic: {
      fontFamily: "body",
      fontSize: "md",
      fontWeight: "light",
      lineHeight: 6,
      fontStyle: "italic",
    },
  },
  link: {
    text: {
      fontFamily: "body",
      fontSize: "md",
      fontWeight: "light",
      lineHeight: 6,
      color: "ui.link.primary",
      textDecoration: "underline",
    },
    bold: {
      fontFamily: "body",
      fontSize: "md",
      fontWeight: "bold",
      lineHeight: 6,
      color: "ui.link.primary",
      textDecoration: "underline",
    },
  },
};
