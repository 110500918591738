import { OPDSFeed, OPDSEntry } from "opds-feed-parser";
import { feedToCollection, entryToBook, SearchData } from "../models";
import * as xml2js from "xml2js";
import { Defect } from "../../shared/errors";

const xmlParser = new xml2js.Parser({ xmlns: true });

/**
 * Chainable handlers
 */

export const entry2Book = (catalogUrl: string) => async (entry: OPDSEntry) => {
  return entryToBook(entry, catalogUrl);
};

export const feed2Collection =
  (info: RequestInfo) => async (feed: OPDSFeed) => {
    const url = typeof info === "string" ? info : info.url;
    return feedToCollection(feed, url);
  };

export const validate =
  <T extends typeof OPDSEntry | typeof OPDSFeed>(type: T, info: RequestInfo) =>
  async (feed: OPDSFeed | OPDSEntry) => {
    const url = typeof info === "string" ? info : info.url;
    if (feed instanceof type) {
      return feed as unknown as T extends typeof OPDSFeed
        ? OPDSFeed
        : OPDSEntry;
    }
    throw new Defect(
      `OPDS Error: Network response was expected to be an OPDS 1 ${type.name}, but was not parseable as such.`,
      { url },
    );
  };

/** Converts an open search description document into the application's internal representation. */
export async function parseSearchData(
  xml: string,
  descriptionUrl: string,
): Promise<SearchData> {
  const result = await xmlParser.parseStringPromise(xml);

  if (result.OpenSearchDescription) {
    const root = result.OpenSearchDescription;
    const description = root["Description"][0]["_"];
    const shortName = root["ShortName"][0]["_"];
    const templateString = root["Url"][0]["$"].template.value.replace(
      "{searchTerms}",
      "",
    );
    const template =
      new URL(templateString, descriptionUrl).toString() + "{searchTerms}";

    if (!description || !shortName || !template) {
      throw new Defect(
        "Invalid search data. Missing description, shortName, or template.",
        { description, shortName, template },
      );
    }

    return {
      url: descriptionUrl,
      description,
      shortName,
      template,
    };
  }

  throw new Defect("Could not parse Open Search Description");
}
