import {
  OPDSEntry,
  OPDSLink,
  OPDSFacetLink,
  OPDSFeed,
  AcquisitionFeed,
  OPDSShelfLink,
} from "opds-feed-parser";
import { OpenEBook, entryToBook } from "../Book";
import { CollectionData, LinkData, LaneData, FacetGroupData } from "./types";
import {
  isCatalogRootLink,
  isCollectionLink,
  isFacetLink,
  isSearchLink,
  isSupportedOpenEBookLink,
  resolve,
} from "../utils";

/**
 * Parse an OPDS1 feed into a Collection model.
 */

export default function feedToCollection(
  feed: OPDSFeed,
  feedUrl: string,
): CollectionData {
  const books: OpenEBook[] = [];
  const navigationLinks: LinkData[] = [];
  let lanes: LaneData[] = [];
  const laneTitles: any[] = [];
  const laneIndex: {
    title: any;
    url: string;
    books: OpenEBook[];
  }[] = [];
  let facetGroups: FacetGroupData[] = [];
  let nextPageUrl: string | undefined = undefined;
  let catalogRootLink: OPDSLink | undefined = undefined;
  let parentLink: OPDSLink | undefined = undefined;
  let shelfUrl: string | undefined = undefined;
  let links: OPDSLink[] = [];

  feed.entries.forEach((entry) => {
    if (feed instanceof AcquisitionFeed) {
      if (entry.links.some(isSupportedOpenEBookLink)) {
        const book = entryToBook(entry, feedUrl);
        const collectionLink = entry.links.find(isCollectionLink);
        if (collectionLink) {
          const { title, href } = collectionLink;

          if (laneIndex[title as any]) {
            laneIndex[title as any].books.push(book);
          } else {
            laneIndex[title as any] = {
              title,
              url: resolve(feedUrl, href),
              books: [book],
            };
            // use array of titles to preserve lane order
            laneTitles.push(title);
          }
        } else {
          books.push(book);
        }
      }
    } else {
      const link = entryToLink(entry, feedUrl);
      if (link) navigationLinks.push(link);
    }
  });

  lanes = laneTitles.reduce((result, title) => {
    const lane = laneIndex[title];
    lane.books = dedupeBooks(lane.books);
    result.push(lane);
    return result;
  }, lanes);
  let facetLinks: OPDSFacetLink[] = [];

  if (feed.links) {
    facetLinks = feed.links.filter(isFacetLink);

    const nextPageLink = feed.links.find((link) => {
      return link.rel === "next";
    });
    if (nextPageLink) {
      nextPageUrl = resolve(feedUrl, nextPageLink.href);
    }

    catalogRootLink = feed.links.find(isCatalogRootLink);

    parentLink = feed.links.find((link) => link.rel === "up");

    const shelfLink = feed.links.find((link) => link instanceof OPDSShelfLink);
    if (shelfLink) {
      shelfUrl = shelfLink.href;
    }

    links = feed.links;
  }

  facetGroups = facetLinks.reduce<FacetGroupData[]>((result, link) => {
    const groupLabel = link.facetGroup;
    const label = link.title;
    const href = resolve(feedUrl, link.href);
    const active = link.activeFacet;
    const facet = { label, href, active };
    const newResult: FacetGroupData[] = [];
    let foundGroup = false;
    result.forEach((group) => {
      if (group.label === groupLabel) {
        const facets = group.facets.concat(facet);
        newResult.push({ label: groupLabel, facets });
        foundGroup = true;
      } else {
        newResult.push(group);
      }
    });
    if (!foundGroup) {
      const facets = [facet];
      newResult.push({ label: groupLabel, facets });
    }
    return newResult;
  }, []);

  function notNull<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
  }
  const filteredLinks = links
    .map((link) => OPDSLinkToLinkData(feedUrl, link))
    // we have to filter out the null values in order for typescript to accept this
    .filter(notNull);

  const searchDataUrl = findSearchLink(feed)?.href ?? null;

  return {
    id: feed.id,
    title: feed.title,
    url: feedUrl,
    lanes,
    books,
    navigationLinks,
    facetGroups,
    nextPageUrl,
    catalogRootLink: OPDSLinkToLinkData(feedUrl, catalogRootLink),
    parentLink: OPDSLinkToLinkData(feedUrl, parentLink),
    shelfUrl,
    links: filteredLinks,
    searchDataUrl,
  };
}

function entryToLink(entry: OPDSEntry, feedUrl: string): LinkData | null {
  const links = entry.links;
  if (links.length > 0) {
    const href = resolve(feedUrl, links[0].href);
    return {
      id: entry.id,
      text: entry.title,
      url: href,
    };
  }
  console.error(
    "Attempting to create Link with undefined url. entry is: ",
    entry,
  );
  return null;
}

function OPDSLinkToLinkData(
  feedUrl: string,
  link: OPDSLink | null = null,
): LinkData | null {
  if (!link || !link.href) {
    return null;
  }

  return {
    url: resolve(feedUrl, link.href),
    text: link.title,
    type: link.rel,
  };
}

export function findSearchLink(feed: OPDSFeed) {
  return feed.links.find(isSearchLink);
}

function dedupeBooks(books: OpenEBook[]): OpenEBook[] {
  // using Map because it preserves key order
  const bookIndex = books.reduce((index, book) => {
    index.set(book.id, book);
    return index;
  }, new Map<any, OpenEBook>());

  return Array.from(bookIndex.values());
}
