const SelectStyle = {
  baseStyle: {
    field: {
      px: 3,
      py: 4,
      borderBottom: "2px solid",
      borderColor: "ui.semiBlack",
      borderRadius: "4px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      bgColor: "ui.gray.xlight",
      fontSize: "md",
      _placeholder: {
        color: "ui.bodyText",
      },
      _focus: {
        borderColor: "ui.teal.400",
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: { variant: null, size: null },
};

export default SelectStyle;
