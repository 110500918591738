import {
  Box,
  chakra,
  Flex,
  HStack,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { SimpleBook } from "oe-shared/models";
import Top10Badge from "components/chakra-ui/Carousel/Top10Badge";
import starsSVG from "components/chakra-ui/svg/stars.svg";
import linesSVG from "components/chakra-ui/svg/lines.svg";
import dotsSVG from "components/chakra-ui/svg/dots.svg";
import { useCarousel, maxWidthStyles } from "ui/chakra";
import React from "react";
import BookCard from "components/chakra-ui/BookCard";

export type ColorScheme = "magenta" | "purple" | "teal";

const bgImageMap: Record<ColorScheme, string> = {
  magenta: `/**/url(${linesSVG.src}), linear-gradient(180deg, #F41666 4.4%, #BF0C4D 100%)`,
  teal: `/**/url(${dotsSVG.src}), linear-gradient(180deg, #0A99A0 0%, #124C4F 100%)`,
  purple: `/**/url(${starsSVG.src}), linear-gradient(180deg, #693CEA 0%, #261C5D 100%)`,
};

const CarouselSection: React.FC<{
  books: SimpleBook[];
  colorScheme: ColorScheme;
  title: string;
  description: string;
}> = ({ books, colorScheme, title, description }) => {
  return (
    <Box
      pb={12}
      bgImage={bgImageMap[colorScheme]}
      color="ui.white"
      backgroundRepeat="repeat-x"
    >
      <Grid
        py={{ base: 4, sm: 6 }}
        px={{ base: 4, md: 12 }}
        mb={2}
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        alignItems="start"
        columnGap={{ base: 3, sm: 4, md: 6 }}
        rowGap={{ base: 3, sm: 3.5 }}
      >
        <GridItem gridColumn="1 / 1" gridRow={{ base: "1/1", md: "1 / 3" }}>
          <Top10Badge colorScheme={colorScheme} />
        </GridItem>
        <GridItem>
          <Text
            textStyle="headers.primary"
            as="h1"
            fontSize={{ base: "xl", md: "3xl", lg: "3xxl" }}
          >
            {title}
          </Text>
        </GridItem>
        <GridItem gridColumn={{ base: "1/3", md: "2 / 3" }} gridRow="2 / 3">
          <Text fontSize={{ base: "md", md: "xl" }} maxW="34em">
            {description}
          </Text>
        </GridItem>
      </Grid>
      <Carousel books={books} colorScheme={colorScheme} />
    </Box>
  );
};

export default CarouselSection;

export const Carousel: React.FC<{
  books: SimpleBook[];
  colorScheme: ColorScheme;
}> = ({ books, colorScheme }) => {
  const {
    bookRefs,
    handleLeftClick,
    handleRightClick,
    handleScroll,
    scrollContainerRef,
    isAtEnd,
    isAtStart,
  } = useCarousel(books);

  return (
    <Flex position="relative" overflowX="hidden">
      <PrevNext
        isPrev
        colorScheme={colorScheme}
        onClick={handleLeftClick}
        disabled={isAtStart}
      />
      <PrevNext
        colorScheme={colorScheme}
        onClick={handleRightClick}
        disabled={isAtEnd}
      />
      <HStack
        tabIndex={0}
        ref={scrollContainerRef as any}
        onScroll={handleScroll as any}
        as="ul"
        spacing={{ base: 2, md: 8 }}
        listStyleType="none"
        alignItems="flex-start"
        transition="transform 300ms ease 100ms"
        overflowX="scroll"
        overflowY="hidden"
        position="relative"
        width="100%"
        mx={{ base: 7, md: 12 }}
        pb={2}
      >
        {books.map((book) => (
          <BookCard
            sx={maxWidthStyles}
            key={book.url}
            book={book}
            ref={bookRefs[book.id]}
          />
        ))}
      </HStack>
    </Flex>
  );
};

const hoverSchemeMap: Record<ColorScheme, { bg: string; color: string }> = {
  magenta: { bg: "ui.magenta.200", color: "ui.magenta.400" },
  purple: { bg: "ui.purple.200", color: "ui.purple.400" },
  teal: { bg: "ui.teal.100", color: "ui.teal.400" },
};

const PrevNext = chakra(
  ({
    isPrev = false,
    className,
    colorScheme,
    onClick,
    disabled = false,
  }: {
    isPrev?: boolean;
    className?: string;
    colorScheme: ColorScheme;
    onClick: React.MouseEventHandler<HTMLButtonElement> &
      React.MouseEventHandler<HTMLDivElement>;
    disabled?: boolean;
  }) => {
    const transition = "300ms ease";
    return (
      <Box
        disabled={disabled}
        onClick={onClick}
        as="button"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="ui.white"
        position="absolute"
        top={{ base: "30%", md: "35%" }}
        left={isPrev ? 0 : "auto"}
        right={isPrev ? "auto" : 0}
        zIndex="dropdown"
        h={{ base: "3.5rem", md: "5rem" }}
        w={{ base: "1.75rem", md: "2.5rem" }}
        maxW={{ base: "29px", md: "45px" }}
        borderLeftRadius="full"
        className={className}
        fontSize={{ base: "7xl", md: "2xxl" }}
        aria-label={isPrev ? "Previous" : "Next"}
        transform={isPrev ? "rotate(180deg)" : "rotate(0deg)"}
        transitionProperty="transform, opacity"
        color="black"
        _hover={{
          ...hoverSchemeMap[colorScheme],
          "& svg": {
            transform: "scale(1.2)",
            transition,
          },
        }}
        _disabled={{
          color: "ui.gray.dark",
          visibility: "hidden",
          opacity: "0",
        }}
        transition={transition}
      >
        <ArrowForwardIosOutlinedIcon />
      </Box>
    );
  },
);
