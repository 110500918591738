import { Box, Spinner, SpinnerProps, Text } from "@chakra-ui/react";
import React from "react";

const LoadingIndicator: React.FC<SpinnerProps> = (props) => {
  return <Spinner thickness="3px" speed="0.5s" size="lg" {...props} />;
};

export const PageLoader: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex="1 0 auto"
    >
      <LoadingIndicator width="50px" height="50px" />
      <Text as="h2">Loading...</Text>
    </Box>
  );
};

export default LoadingIndicator;
