import {
  OPDSLink,
  OPDSArtworkLink,
  OPDSAcquisitionLink,
  OPDSCatalogRootLink,
  OPDSCollectionLink,
  SearchLink,
  OPDSFacetLink,
} from "opds-feed-parser";
import { TrackOpenBookRel, AxisNowWebpubMediaType } from "@nypl/opds/opds1";
import { OPDS1 } from "@nypl/opds";
import { BorrowableBook, FulfillableBook, OpenEBook } from "../Book";

export function isAcquisitionLink(link: OPDSLink): link is OPDSAcquisitionLink {
  return link instanceof OPDSAcquisitionLink;
}

export function isArtworkLink(link: OPDSLink): link is OPDSArtworkLink {
  return link instanceof OPDSArtworkLink;
}

export function isRelatedLink(link: OPDSLink) {
  return link.rel === "related";
}

export function isTrackOpenBookLink(link: OPDSLink) {
  return link.rel === TrackOpenBookRel;
}

export function isSearchLink(link: OPDSLink): link is SearchLink {
  return link instanceof SearchLink;
}

export function isFacetLink(link: OPDSLink): link is OPDSFacetLink {
  return link instanceof OPDSFacetLink;
}

export function isCatalogRootLink(link: OPDSLink): link is OPDSCatalogRootLink {
  return link instanceof OPDSCatalogRootLink;
}

export function isSupportedOpenEBookLink(
  link: OPDSLink,
): link is OPDSAcquisitionLink {
  if (isAcquisitionLink(link)) {
    if (
      link.type === AxisNowWebpubMediaType ||
      link.indirectAcquisitions?.[0]?.type === AxisNowWebpubMediaType
    ) {
      return true;
    }
    return false;
  }
  return false;
}

export function isBorrowLink(link: OPDSAcquisitionLink) {
  if (
    link.rel === OPDSAcquisitionLink.BORROW_REL &&
    link.indirectAcquisitions.some(
      (format) => format.type === AxisNowWebpubMediaType,
    )
  ) {
    return true;
  }
  return false;
}

export function isCollectionLink(link: OPDSLink): link is OPDSCollectionLink {
  return link instanceof OPDSCollectionLink;
}

export function bookIsFulfillable(book: OpenEBook): book is FulfillableBook {
  return book.status === "fulfillable";
}

export function bookIsBorrowable(book: OpenEBook): book is BorrowableBook {
  return book.status === "borrowable";
}

export function resolve(base: string, relative: string) {
  return new URL(relative, base).toString();
}

export function findRevokeUrl(links: OPDSLink[]) {
  return links.find((link) => link.rel === OPDS1.RevokeLinkRel)?.href ?? null;
}

export function getLoanExpirationString(book: OpenEBook) {
  const availableUntil =
    book.availability?.until &&
    new Date(book.availability.until).toLocaleString("en-En", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });

  if (availableUntil) return `Available until ${availableUntil}`;

  return null;
}
