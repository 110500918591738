import { extendTheme } from "@chakra-ui/react";
import { baseTheme } from "ui/chakra";
import { ColorScheme, primaryColors } from "ui/chakra/theme/components/button";

const baseButton = baseTheme.components.Button;

export const Button: ReturnType<typeof extendTheme>["components"]["Button"] = {
  ...baseButton,
  variants: {
    ...baseButton.variants,
    rectangular: rectangularVariant,
  },
};

function rectangularVariant(props: { colorScheme?: ColorScheme }) {
  const color = primaryColors(props.colorScheme);
  return {
    ...color,
    borderRadius: "soft",
    shadow: "none",
  };
}
