const shadows = {
  button: "0px 1px 2px rgba(0, 0, 0, 0.25), 0px 2px 1px rgba(0, 0, 0, 0.3)",
  icon: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
  modal: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px -1px 4px rgba(0, 0, 0, 0.25)",
  carouselBook:
    "0px 3px 2px rgba(0, 0, 0, 0.15), 0px 2px 1px rgba(0, 0, 0, 0.3)",
  gridBook: "0px 3px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15)",
  transparent:
    "inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)",
};

export default shadows;
