import { Box, Link, ListItem, OrderedList } from "@chakra-ui/react";
import React from "react";

export const createTOCAnchors = (children: React.ReactNode) => {
  let anchorRef = "#";
  let id;
  // standardize heading text & convert to ids and anchor refs
  if (typeof children == "string") {
    id = children.replace(/ /g, "_").toLocaleLowerCase();
    anchorRef = "#" + id;
  }
  return { anchorRef, id };
};

export const TOC = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box as="nav" aria-label="Questions" marginBottom="md">
    <OrderedList spacing={1} marginInlineStart={0}>
      {children}
    </OrderedList>
  </Box>
);

export const TOCItem = ({ children }: React.PropsWithChildren<unknown>) => {
  const { anchorRef } = createTOCAnchors(children);
  return (
    <ListItem listStyleType="none">
      <Link
        bgColor="ui.gray.xlight"
        borderRadius={5}
        padding="2"
        variant="TOC"
        href={anchorRef}
        display="block"
        _hover={{ bgColor: "ui.gray.light" }}
      >
        {children}
      </Link>
    </ListItem>
  );
};
