import { tokenExpired, verifyToken } from "oe-shared/auth";
import { OpenEBooksAuthCredentials } from "oe-shared/auth/types";
import Cookies from "js-cookie";
import { NextRouter } from "next/router";
import {
  AUTH_COOKIE_NAME,
  CATALOG_ROOT_PATH,
  SIGNIN_CLEVER_CALLBACK_PATH,
  SIGNIN_CLEVER_PATH,
  SIGNIN_FIRSTBOOK_PATH,
  SIGNIN_PICKER_PATH,
  SIGNIN_REDIRECT_QUERY_PARAM,
} from "utils/constants";
import { CM, IS_SERVER } from "utils/env";

export const getEnglishValue = (arr: [{ language: string; value: string }]) =>
  arr.find((item) => item.language === "en")?.value;

/**
 * Gets credentials from cookies & verifies that the token has not expired.
 * You provide the cookies. Used on the server.
 */
export async function getCredentials(
  cookies: Record<string, string> | Partial<{ [key: string]: string }>,
): Promise<OpenEBooksAuthCredentials | undefined> {
  const authCookie: string | undefined = cookies[AUTH_COOKIE_NAME];
  return parseAndVerifyCreds(authCookie);
}

/**
 * Takes a possible string cookie value, parses it, verifies it, and returns
 */
export async function parseAndVerifyCreds(
  creds: string | undefined,
): Promise<OpenEBooksAuthCredentials | undefined> {
  if (creds) {
    const credentials = JSON.parse(creds);
    const updatedCredentials = await verifyToken(
      credentials,
      CM.firstBookAuthUrl,
    );
    renewCookieBrowser(updatedCredentials);
    return updatedCredentials;
  }
}

/**
 * Gets credentials from cookies using js-cookie & verifies
 * that the token has not expired. You provide the cookies.
 * You don't have to provide anything to this functiion.
 */
export async function getCredentialsBrowser(): Promise<
  OpenEBooksAuthCredentials | undefined
> {
  const credentials = Cookies.get(AUTH_COOKIE_NAME);
  if (credentials) {
    const parsedCredentials = JSON.parse(credentials);
    const updatedCredentials = await verifyToken(
      parsedCredentials,
      CM.firstBookAuthUrl,
    );
    renewCookieBrowser(updatedCredentials);
    return updatedCredentials;
  }
  return undefined;
}

/**
 * Sets the auth cookie in the browser using js-cookie
 */
export function setAuthCookieBrowser(credentials: OpenEBooksAuthCredentials) {
  Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(credentials), {
    path: "/",
    sameSite: "strict",
  });
}

export function renewCookieBrowser(credentials: OpenEBooksAuthCredentials) {
  if (!IS_SERVER && tokenExpired(credentials)) {
    setAuthCookieBrowser({ ...credentials });
  }
}

export function performAuthRedirect(router: NextRouter) {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo =
    urlParams.get(SIGNIN_REDIRECT_QUERY_PARAM) ?? CATALOG_ROOT_PATH;

  const finalPath = isValidRedirect(redirectTo)
    ? redirectTo
    : CATALOG_ROOT_PATH;

  const finalRedirect = new URL(finalPath, window.location.origin);
  router.push(finalRedirect.toString(), undefined, { shallow: false });
}

const INVALID_REDIRECTS = [
  "/",
  SIGNIN_PICKER_PATH,
  SIGNIN_CLEVER_PATH,
  SIGNIN_CLEVER_CALLBACK_PATH,
  SIGNIN_FIRSTBOOK_PATH,
];
export function isValidRedirect(redirectTo: string) {
  return !INVALID_REDIRECTS.includes(redirectTo);
}
