import { CM } from "../../src/utils/mockEnv";
export const AUTH_COOKIE_NAME = "OEW_AUTH_COOKIE";

const { catalogUrl } = CM;

// Base Paths
export const HOME_PATH = "/";
export const APP_PATH = "/app";
export const CONTACT_PATH = "/contact";
export const SIGNIN_PATH = `${APP_PATH}/signin`;
export const LOANS_PATH = `${APP_PATH}/loans`;
export const READ_PATH = `${APP_PATH}/read`;
export const FIRSTBOOK_SIGNIN_PATH = `${SIGNIN_PATH}/method/firstbook`;
export const CLEVER_SIGNIN_PATH = `${SIGNIN_PATH}/method/clever`;
export const CLEVER_SIGNIN_CALLBACK_PATH = `${SIGNIN_PATH}/method/clever/callback`;
const COLLECTION_PATH = `${APP_PATH}/collection`;
const BOOK_PATH = `${APP_PATH}/book`;

// BOOK DETAILS ENDPOINTS
export const ALMOST_MIDNIGHT_BOOK_ENDPOINT = `${catalogUrl}/works/Axis%20360%20ID/0017409891`;
// fulfillment url on the backend
export const ALMOST_MIDNIGHT_BOOK_FULFILLMENT_ENDPOINT = `${catalogUrl}/works/121721/fulfill/15`;
// nextjs api route to get fulfillment info
export const ALMOST_MIDNIGHT_BOOK_FULLFILLMENT_API_ROUTE = `/api/fulfillment/${encodeURIComponent(
  ALMOST_MIDNIGHT_BOOK_FULFILLMENT_ENDPOINT,
)}`;

export const NOT_FOUND_BOOK_ENDPOINT = `${catalogUrl}/works/doesnt-exist`;
export const NOT_FOUND_BOOK_PATH = `${BOOK_PATH}/${encodeURIComponent(
  NOT_FOUND_BOOK_ENDPOINT,
)}`;

export const ERROR_BOOK_ENDPOINT_502 = `${catalogUrl}/works/error-502`;
export const ERROR_BOOK_PATH_502 = `${BOOK_PATH}/${encodeURIComponent(
  ERROR_BOOK_ENDPOINT_502,
)}`;

export const ERROR_BOOK_ENDPOINT_401 = `${catalogUrl}/works/error-401`;
export const ERROR_BOOK_PATH_401 = `${BOOK_PATH}/${encodeURIComponent(
  ERROR_BOOK_ENDPOINT_502,
)}`;

export const MIDNIGHT_HOUR_CM_ENDPONT = `${catalogUrl}/works/Axis%20360%20ID/0018642364`;
export const MIDNIGHT_HOUR_BOOK_DETAIL = `${BOOK_PATH}/${encodeURIComponent(
  MIDNIGHT_HOUR_CM_ENDPONT,
)}`;

// COLLECTION ENDPOINTS For each grades.
export const HIGH_SCHOOL_COLLECTION_ENDPOINT = `${catalogUrl}/groups/406`;
export const MIDDLE_SCHOOL_COLLECTION_ENDPOINT = `${catalogUrl}/groups/417`;
export const EARLY_GRADES_COLLECTION_ENDPOINT = `${catalogUrl}/groups/429`;
export const ALL_ACCESS_COLLECTION_ENDPOINT = `${catalogUrl}/groups/`;

// COLLECTION PATHS FOR ALL ACCESS USERS
export const HIGH_SCHOOL_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${HIGH_SCHOOL_COLLECTION_ENDPOINT}`,
)}`;
export const MIDDLE_GRADES_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${MIDDLE_SCHOOL_COLLECTION_ENDPOINT}`,
)}`;
export const EARLY_GRADES_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${EARLY_GRADES_COLLECTION_ENDPOINT}`,
)}`;
export const ALL_ACCESS_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${ALL_ACCESS_COLLECTION_ENDPOINT}`,
)}`;

// ALL ACCESS PATHS
export const ALL_ACCESS_INTEGRATION_TESTING_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/440?entrypoint=Book`,
)}`;
export const ALL_ACCESS_AUTHOR_RECOMMENDATIONS_PATH_HUNTER_C_C = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/contributor/Hunter%2C%20C.%20C./eng/All%2BAges%2CChildren%2CYoung%2BAdult`,
)}`;
export const ALL_ACCESS_DETAIL_BOOK_PATH_ALMOST_MIDNIGHT = `${BOOK_PATH}/${encodeURIComponent(
  ALMOST_MIDNIGHT_BOOK_ENDPOINT,
)}`;
// HIGH SCHOOL PATHS
export const HIGH_SCHOOL_STAFF_PICKS_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/407?entrypoint=Book`,
)}`;
export const HIGH_SCHOOL_ACTION_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/408?entrypoint=Book`,
)}`;
export const HIGH_SCHOOL_AUTHOR_RECOMMENDATIONS_PATH_JENNIFER_RUSH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/contributor/Rush%2C%20Jennifer/eng/All%2BAges%2CChildren%2CYoung%2BAdult`,
)}`;
export const HIGH_SCHOOL_ROMANCE_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/414?entrypoint=Book`,
)}`;
export const HIGH_SCHOOL_DETAIL_BOOK_PATH_HEART_OF_A_CHAMPION = `${BOOK_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/Axis%20360%20ID/0013217610`,
)}`;
export const HIGH_SCHOOL_DETAIL_BOOK_PATH_ALTERED = `${BOOK_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/Axis%20360%20ID/0013215327`,
)}`;

// MIDDLE SCHOOL PATHS
export const MIDDLE_GRADES_STAFF_PICKS_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/418?entrypoint=Book`,
)}`;
export const MIDDLE_GRADES_COMICS_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/421?entrypoint=Book`,
)}`;
export const MIDDLE_GRADES_DETAIL_BOOK_PATH_ABBY_CARNELIA = `${BOOK_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/Axis%20360%20ID/0014727641`,
)}`;

// EARLY GRADES PATHS
export const EARLY_GRADES_CHAPTER_BOOKS_COLLECTION_PATH = `${COLLECTION_PATH}/${encodeURIComponent(
  `${catalogUrl}/feed/431?entrypoint=Book`,
)}`;
export const EARLY_GRADES_DETAIL_BOOK_PATH_ALL_ABOUT_ELLIE = `${BOOK_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/Axis%20360%20ID/0016687577`,
)}`;

// LOANED BOOK READ PATHS
export const ALMOST_MIDNIGHT_READ_PATH = `${READ_PATH}/${encodeURIComponent(
  `${catalogUrl}/works/121907/fulfill/15`,
)}`;

// Axisnow endpoints for almost midnight book
export const AXISNOW_LICENSE_ENDPOINT =
  "https://node.axisnow.com/license/:bookVaultId/:deviceId/:clientIp/:isbn/:modulus/:exponent";
export const ALMOST_MIDNIGHT_CONTAINER =
  "https://frontdoor.axisnow.com/content/stream/9781466892859/META-INF/container.xml";
export const ALMOST_MIDNIGHT_ENCRYPTION =
  "https://frontdoor.axisnow.com/content/stream/9781466892859/META-INF/encryption.xml";
export const ALMOST_MIDNIGHT_PACKAGE =
  "https://frontdoor.axisnow.com/content/stream/9781466892859/OEBPS/package.opf";
export const ALMOST_MIDNIGHT_TOC =
  "https://frontdoor.axisnow.com/content/stream/9781466892859/OEBPS/toc.ncx";
export const ALMOST_MIDNIGHT_NAV =
  "https://frontdoor.axisnow.com/content/stream/9781466892859/OEBPS/nav.xhtml";

// BORROW & REVOKE BOOK ENDPOINTS
export const ALMOST_MIDNIGHT_FETCH_URL = `${catalogUrl}/works/Axis%20360%20ID/0017409891/borrow/15`;
export const ALMOST_MIDNIGHT_REVOKE_URL = `${catalogUrl}/loans/121907/revoke`;

export const V2_IFRAME_SELECTOR = "#mainContent";

// BOOKS USED FOR ERRORS, we use these to test the error handling for MSW intercepts eventhough they are totally valid books
// JFK is used for a borrow error
export const JFK_BORROW_ENDPOINT = `${catalogUrl}/works/Axis%20360%20ID/0016692239/borrow/15`;
export const JFK_BOOK_ENDPOINT = `${catalogUrl}/works/Axis%20360%20ID/0016692239`;
export const JFK_BOOK_PATH = `${BOOK_PATH}/${encodeURIComponent(
  JFK_BOOK_ENDPOINT,
)}`;
