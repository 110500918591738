import { isOEBFailure } from "oe-shared/errors";
import useSWRAbortable from "hooks/useSWRAbortable";
import { OPDS1 } from "interfaces";
import { OpenEBook } from "oe-shared/models";
import React from "react";
import { KeyedMutator } from "swr";
import { IS_SERVER, CM } from "utils/env";
import Head from "next/head";
import { bookIsFulfillable } from "oe-shared/models";
import useGetCredentials from "hooks/useGetCredentials";
import useMounted from "hooks/useMounted";
import { fetchLoans } from "dataflow/fetch";
import { Defect } from "shared/errors";

export type UserState = {
  loans: OpenEBook[] | undefined;
  isLoading: boolean;
  /**
   * If we are loading for the very first time.
   */
  isFirstLoading: boolean;
  error: any;
  /**
   * Allows optimistically updating the loans in the cache, and then
   * immediately revalidating with a fresh loans request to the backend.
   */
  mutateLoans: KeyedMutator<OpenEBook[]>;
  /**
   * Allows cancelling inflight loans requests. Use before making
   * any fulfillment request.
   */
  cancelInflightLoans: () => void;
};

export const UserContext = React.createContext<UserState | undefined>(
  undefined,
);

/**
 * Here we fetch the loans and provide functions to sign in
 * and sign out. Calling mutate() will invalidate the SWR
 * cache and therefore cause a refetch. The key to the cache
 * includes the shelfUrl, token and auth method type, so if any of
 * those change it will cause a refetch.
 */
export const UserProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const credentials = useGetCredentials();
  const isMounted = useMounted();
  const { loansUrl } = CM;
  const { data, mutate, isValidating, cancelInflight, error } = useSWRAbortable(
    // pass null if there are no credentials or shelfUrl to tell SWR not to fetch at all.
    credentials && loansUrl
      ? [loansUrl, credentials?.token, credentials?.methodType]
      : null,
    ([url, token]) => fetchLoans(url, { headers: { Authorization: token } }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (error && isOEBFailure(error) && error._tag !== "UnauthorizedError") {
    throw error;
  }

  const isLoading = isValidating;
  const isFirstLoading = IS_SERVER || !isMounted || (isValidating && !data);

  const user: UserState = {
    isLoading,
    isFirstLoading,
    loans: data,
    mutateLoans: mutate,
    cancelInflightLoans: cancelInflight,
    error,
  };

  return (
    <UserContext.Provider value={user}>
      {children}
      {/* Prefetch all the loaned books in the Header for a faster load time when users click on a book */}
      {data !== undefined && <PrefetchFulfillmentBooks books={data} />}
    </UserContext.Provider>
  );
};

const PrefetchFulfillmentBooks: React.FC<{
  books: OpenEBook[];
}> = ({ books }) => {
  const fulfillmentLinks = [];

  for (const book of books) {
    if (!bookIsFulfillable(book)) continue;
    const axisNowLinks = book.fulfillmentLinks.filter(
      (link) => link.contentType === OPDS1.AxisNowWebpubMediaType,
    );

    const singleFulfillment =
      axisNowLinks.length === 1 ? axisNowLinks[0] : undefined;

    if (singleFulfillment) {
      const fulfillmentLink = new URL(
        `/api/fulfillment/${encodeURIComponent(singleFulfillment.url)}`,
        window.location.origin,
      );

      fulfillmentLinks.push(fulfillmentLink.toString());
    }
  }

  if (!fulfillmentLinks.length) return null;

  return (
    <>
      <Head>
        {fulfillmentLinks.map((link: any) => (
          <link rel="prefetch" href={link} key={link} />
        ))}
      </Head>
    </>
  );
};

export default function useUser() {
  const context = React.useContext(UserContext);
  if (typeof context === "undefined") {
    throw new Defect("useUser must be used within a UserProvider");
  }
  return context;
}
