const headerVariant = {
  fontSize: { sm: "md" },
  lineHeight: { sm: "shorter", md: "base" },
  textDecoration: "none",
  letterSpacing: "0.01em",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  p: 2,
  _hover: {
    bgColor: "ui.white",
    textDecoration: "none",
  },
  color: "ui.black",
};

const LinkStyle = {
  baseStyle: {
    fontSize: "md",
    fontWeight: "normal",
    lineHeight: "shorter",
    color: "ui.teal.400",
    textDecoration: "underline",
  },
  sizes: {},
  variants: {
    header: headerVariant,
    TOC: TOCVariant,
  },
  defaultProps: {},
};

function TOCVariant() {
  return {
    textDecoration: "none",
    color: "ui.bodyText",
    fontSize: ["3xl", "3xl"],
    fontWeight: "bold",
    lineHeight: "shorter",
    _hover: {
      textDecoration: "none",
    },
  };
}

export default LinkStyle;
