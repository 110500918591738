import React, { ReactNode } from "react";
import Page from "components/chakra-ui/Page";
import { SearchData } from "oe-shared/models";
import Layout from "components/chakra-ui/Layout";

/* LayoutPage is a Page with Header and Footer from Layout, this should be used to wrap pages within the app with sitewide navigation. */

const LayoutPage: React.FC<
  React.PropsWithChildren<{
    searchData?: SearchData;
    children: ReactNode;
  }>
> = ({ children, searchData }) => {
  return (
    <Page>
      <Layout searchData={searchData}>{children}</Layout>
    </Page>
  );
};

export default LayoutPage;
