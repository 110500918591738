import React from "react";
import Head from "next/head";

export const APP_TITLE = "Open eBooks | Books for Free. Read for Fun.";

const SiteHead: React.FC = () => {
  return (
    <Head>
      <title>{APP_TITLE}</title>
      <meta
        name="description"
        content="Open eBooks gives access to 1000s of free ebooks for K-12  students from participating schools. Find out how to get started."
      ></meta>
    </Head>
  );
};

export default SiteHead;
