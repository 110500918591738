import React from "react";
import { Provider as ReakitProvider } from "reakit";
import { UserProvider } from "components/context/UserContext";
import { SWRConfig } from "swr";
import swrConfig from "utils/swrConfig";

/**
 * Combines all of the apps context provider into a single component for simplicity
 */
const AppContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <SWRConfig value={swrConfig}>
      <ReakitProvider>
        <UserProvider>{children}</UserProvider>
      </ReakitProvider>
    </SWRConfig>
  );
};

export default AppContextProvider;
