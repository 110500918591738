/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ExploreOutlineIcon from "@mui/icons-material/ExploreOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { HEADER_HEIGHT } from "../theme/foundations/sizes";
import { useRouter } from "next/router";
import {
  Box,
  BoxProps,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Link,
  List,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { AuthButton } from "components/chakra-ui/AuthButton";
import {
  AllBooksHeaderLink,
  DesktopHeaderLink,
  MobileHeaderLink,
  MyBooksHeaderLink,
} from "components/chakra-ui/header";
import { CATALOG_ROOT_PATH } from "utils/constants";
import { LOANS_PATH } from "../../../../playwright/support/routes";
import Search from "../Search";
import logoIcon from "components/chakra-ui/svg/logo-icon.svg";
import logoHorizontal from "components/chakra-ui/svg/logo-horizontal.svg";
import useGetCredentials from "hooks/useGetCredentials";
import { SearchData } from "oe-shared/models";

type HeaderProps = {
  searchData?: SearchData;
};

const showOnMobile = {
  display: "flex",
  marginLeft: "auto",
  [`@media (min-width: 30em)`]: {
    display: "flex",
  },
  [`@media (min-width: 52em)`]: {
    display: "none",
  },
} as const;

export const showOnDesktop = {
  display: "none",
  [`@media (min-width: 30em)`]: {
    display: "none",
  },
  [`@media (min-width: 52em)`]: {
    display: "flex",
  },
} as const;

type HeaderLogoProps = { src: string } & React.ComponentProps<typeof Box> &
  BoxProps;

const HeaderLogo: React.FC<HeaderLogoProps> = ({ src, ...props }) => {
  return (
    <Link href="/" aria-label="Open eBooks Homepage">
      <Box
        as="img"
        mx={2}
        paddingRight={{ base: 0, md: 1.5, lg: 3 }}
        src={src}
        alt="Open Ebooks Logo"
        alignSelf="center"
        maxW="100%"
        width={38}
        {...props}
      />
    </Link>
  );
};

type HeaderLogosProps = {
  iconLogoSrc: string;
  horizontalLogoSrc: string;
};

const HeaderLogos: React.FC<HeaderLogosProps> = ({
  iconLogoSrc,
  horizontalLogoSrc,
}) => {
  return (
    <>
      <HeaderLogo
        src={iconLogoSrc}
        sx={{
          display: "block",
          [`@media (min-width: 30em)`]: {
            display: "none",
          },
          [`@media (min-width: 52em)`]: {
            display: "block",
          },
          [`@media (min-width: 65em)`]: {
            display: "none",
          },
        }}
      />
      <HeaderLogo
        src={horizontalLogoSrc}
        width={[200, 200, 260]}
        sx={{
          display: "none",
          [`@media (min-width: 30em)`]: {
            display: "block",
          },
          [`@media (min-width: 52em)`]: {
            display: "none",
          },
          [`@media (min-width: 65em)`]: {
            display: "block",
          },
        }}
      />
    </>
  );
};

const DesktopNav: React.FC<HeaderProps> = ({ searchData }) => {
  const router = useRouter();
  const credentials = useGetCredentials();
  const isAuthenticated = !!credentials;
  const isAppPage =
    router.pathname.includes("/app") && !router.pathname.includes("/signin");
  return (
    <Box
      display="flex"
      flex="1"
      alignItems="center"
      justifyContent="flex-end"
      as="nav"
      aria-label="main menu"
      sx={showOnDesktop}
    >
      {isAppPage && isAuthenticated ? (
        <Box flexGrow={1}>
          <Search searchData={searchData} />
        </Box>
      ) : (
        <>
          <List display="flex">
            <DesktopHeaderLink href="/">Home</DesktopHeaderLink>
            <DesktopHeaderLink href="/about">About</DesktopHeaderLink>
            <DesktopHeaderLink href="/faq">FAQs</DesktopHeaderLink>
            <DesktopHeaderLink href="/educators">Educators</DesktopHeaderLink>
            <DesktopHeaderLink href="/partners">Partners</DesktopHeaderLink>
          </List>
          {isAuthenticated && (
            <chakra.span
              color="ui.black"
              fontSize="md"
              fontWeight="black"
              alignSelf="center"
            >
              |
            </chakra.span>
          )}
        </>
      )}
      {isAuthenticated && (
        <List display="flex">
          <AllBooksHeaderLink />
          <MyBooksHeaderLink />
        </List>
      )}
      <AuthButton
        marginLeft={3}
        flexShrink={0}
        colorScheme="teal"
        boxShadow="none"
      />
    </Box>
  );
};

const MobileNav: React.FC = () => {
  const credentials = useGetCredentials();
  const isAuthenticated = !!credentials;
  const { isOpen, onClose, onToggle } = useDisclosure();
  const btnRef = React.useRef(null);
  return (
    <>
      <IconButton
        bg="transparent"
        p={0}
        borderRadius={0}
        _hover={{ bg: "none" }}
        onClick={onToggle}
        aria-label="Toggle Navigation Menu"
        icon={isOpen ? <CloseOutlinedIcon /> : <MenuOutlinedIcon />}
        ref={btnRef}
        sx={showOnMobile}
      />
      <Drawer
        size="full"
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay zIndex="dropdown" />
        <DrawerContent
          as="nav"
          aria-label="main menu"
          mt={HEADER_HEIGHT}
          overflow="scroll"
        >
          <List>
            <MobileHeaderLink href="/" icon={<HomeOutlinedIcon />}>
              Home
            </MobileHeaderLink>
            <MobileHeaderLink href="/about" icon={<InfoOutlinedIcon />}>
              About
            </MobileHeaderLink>
            <MobileHeaderLink href="/faq" icon={<QuestionAnswerOutlinedIcon />}>
              FAQs
            </MobileHeaderLink>
            <MobileHeaderLink href="/educators" icon={<SchoolOutlinedIcon />}>
              Educators
            </MobileHeaderLink>
            <MobileHeaderLink href="/partners" icon={<HandshakeOutlinedIcon />}>
              Partners
            </MobileHeaderLink>
            <MobileHeaderLink href="/privacy" icon={<PrivacyTipOutlinedIcon />}>
              Privacy
            </MobileHeaderLink>
            {isAuthenticated && (
              <>
                <Divider borderWidth={1} borderColor="ui.gray.light" />
                <MobileHeaderLink
                  href={CATALOG_ROOT_PATH}
                  icon={<ExploreOutlineIcon />}
                >
                  Discover
                </MobileHeaderLink>
                <MobileHeaderLink
                  href={LOANS_PATH}
                  icon={<LocalLibraryOutlinedIcon />}
                >
                  My books
                </MobileHeaderLink>
              </>
            )}
          </List>
          <AuthButton iconSpacing={4} variant="menuItem" />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const Header: React.FC<HeaderProps> = ({ searchData }) => {
  return (
    <Flex
      position="sticky"
      // "sticky" gives the skpinav a high zIndex, but the skip to content link still displays on top
      zIndex="sticky"
      top={0}
      left={0}
      as="header"
      height="header"
      direction="row"
      bg="ui.gray.xlight"
      alignItems="center"
      // justifyContent="space-between"
      px={{ base: 3.5, lg: 10 }}
    >
      <HeaderLogos
        iconLogoSrc={logoIcon.src}
        horizontalLogoSrc={logoHorizontal.src}
      />
      <DesktopNav searchData={searchData} />
      <MobileNav />
    </Flex>
  );
};
