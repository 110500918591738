import { extendTheme } from "@chakra-ui/react";
import { baseTheme } from "ui/chakra";
import sizes from "./foundations/sizes";
import { textStyles } from "./textStyles";
import { fonts } from "./foundations/fonts";
import { Button } from "./components/button";

const webTheme = extendTheme({
  ...baseTheme,
  sizes: { ...baseTheme.sizes, ...sizes },
  textStyles,
  fonts,
  components: {
    ...baseTheme.components,
    Button,
  },
});

export default webTheme;
