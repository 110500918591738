import { Colors } from "@chakra-ui/react";

const magentaPrimaryGradient =
  "linear-gradient(180deg, #FF6AAF 0%, #F41666 100%)";
const purpleSecondaryGradient =
  "linear-gradient(180deg, #693CEA 0%, #261C5D 100%)";

const colors: Colors = {
  transparent: "transparent",

  ui: {
    white: "#FFFFFF",
    black: "#000000",
    semiBlack: "#222222",
    bodyText: "#424242",
    danger: "#EC445A",
    blue: {
      "100": "#B9DFFD",
      "200": "#8CCAFC",
      "300": "#60B5FB",
      "400": "#33A0FA",
      "500": "#068BF9",
      "600": "#056FC7",
      "700": "#045495",
      "800": "#023864",
      "900": "#011C32",
    },
    magenta: {
      100: "#FFF2F8",
      200: "#FFDFEE",
      300: "#FF6AAF",
      400: "#F41666",
      500: "#BF0C4D",
      gradient: {
        primary: magentaPrimaryGradient,
      },
    },
    teal: {
      100: "#E0F2F3",
      200: "#B1F8FC",
      300: "#34CFD8",
      400: "#0C838A",
      500: "#08575B",
    },
    purple: {
      100: "#EDEBFF",
      200: "#CAC2FF",
      300: "#835EEE",
      400: "#693CEA",
      500: "#4D2DA6",
      gradient: {
        secondary: purpleSecondaryGradient,
      },
    },
    gray: {
      xxdark: "#424242",
      xdark: "#616161",
      dark: "#8A8A8A",
      light: "#D9D9D9",
      lightWarm: "#EFEDEB",
      xlight: "#F5F5F5",
      xxlight: "#FAFAFA",
    },
    clever: {
      blue: "#4274F6",
    },
    firstbook: {
      blue: "#00ACE4",
    },
  },
};

export default colors;
